import { Observable } from 'rxjs';
import {
    FileUploadDto,
    FileUploadRequestDto,
    ResponseDownloadUpload,
    ResponseUploadShow,
} from '../dtos/files.dto';

export abstract class UploadRepository {
    abstract upload(body: FileUploadDto): Observable<any>;
    abstract download(id: string): Observable<ResponseDownloadUpload>;
    abstract show(id: string): Observable<ResponseUploadShow>;
    abstract uploadRequest(body: FileUploadRequestDto): Observable<any>;
}
